<template>
  <div class="material-profile col-md-5 col-sm-12 q-pa-sm">
    <div class="flex row items-center justify-between">
      <div class="col-12 flex column">
        <label class="title-info q-mt-md">Título</label>
        <q-input
          v-model="questions.title"
          bg-color="grey-3"
          dense
          placeholder="Título"
          color="default-pink"
          input-style="border-radius: 8px;"
          type="text"
          outlined
          lazy-rules
          :rules="[(val) => val != '' || 'Campo obrigatório']"
        />
      </div>
    </div>

   
    <!-- <div class="row col-12 q-mt-md items-end">
          <div class="col-5">
            <label class="title-info">Número de Tentativas</label>
            <q-input
              v-model.number="questions.number_attempts"
              bg-color="grey-3"
              dense
              color="default-pink"
              aria-placeholder="Número de tentativa"
              input-style="border-radius: 8px;"
              type="number"
              outlined
              lazy-rules
              :rules="[(val) => val != 0 || 'Campo obrigatório']"
            />
          </div>
          <div class="col-5 q-ml-md">
            <label class="title-info">Número de Questões</label>
            <q-input
              v-model.number="questions.number_questions"
              bg-color="grey-3"
              dense
              color="default-pink"
              aria-placeholder="Número de tentativa"
              input-style="border-radius: 8px;"
              type="number"
              outlined
              lazy-rules
              :rules="[(val) => val != 0 || 'Campo obrigatório']"
            />
          </div>
        </div> -->
    <div class="row col-12 q-mt-md items-end">
      <div class="col-12">
        <label class="title-info">Período</label>
      </div>
      <div class="col-5">
        <q-input
          v-model="questions.start_date"
          bg-color="grey-3"
          dense
          color="default-pink"
          input-style="border-radius: 8px;"
          outlined
          type="date"
          :rules="[(val) => val != '' || 'Campo obrigatório']"
        >
        </q-input>
      </div>
      <div class="col-5 q-ml-md">
        <q-input
          v-model="questions.end_date"
          bg-color="grey-3"
          dense
          color="default-pink"
          input-style="border-radius: 8px;"
          outlined
          type="date"
          lazy-rules
          :rules="[(val) => val != '' || 'Campo obrigatório']"
        >
        </q-input>
      </div>
    </div>
    <div class="col-12 row q-mt-md">
      <div class="col-12">
        <label class="title-info">Post Associado</label>
      </div>
      
      <div class="col-12">
        <q-select
          v-model="questions.post"
          :options="postList"
          bg-color="grey-3"
          color="default-pink"
          class="col-11"
          outlined
          dense
          emit-value
          map-options
          option-value="id"
          option-label="label"
          :loading="loading"
          @virtual-scroll="onScroll"
          @update:model-value="() => test(questions.post)"
          lazy-rules
          :rules="[(val) => val != null || 'Campo obrigatório']"
        />
      </div>
    </div>
    <div class="col-12 row q-mt-md">
      <div class="col-12">
        <label class="title-info">Orientações</label>
      </div>
      <div class="col-12">
        <q-input
          bg-color="grey-3"
          color="default-pink"
          v-model="questions.description"
          dense
          outlined
          rounded
          type="textarea"
          lazy-rules
          :rules="[(val) => val != '' || 'Campo obrigatório']"
        />
      </div>
    </div>
  </div>
</template>

<script>
//----SERVICES
import PostDataServices from "@/services/PostDataServices";

//----VEUJS
import { onMounted, ref } from 'vue';

export default {
  name: "FormLightingEvaluation",
  props: {
    questions: Object,
  },
  setup(props){
    //----VARIABLES
    let postList = ref([])
    let pageNumber = ref(1)

    //----CONSTANTS
    const postDataServices = new PostDataServices();
    const loading = ref(false)
    const pageSize = ref(10)

    onMounted(()=>{
      _getPostList();
      // _loadPostValue();
    })


    async function _getPostList(){
      await postDataServices.getAll(pageNumber.value, pageSize.value).then((response)=>{
        response.data.data.map((post)=>{
          postList.value.push({
            id: post.postId,
            label: post.title
          });
        })
        pageNumber.value += 1;
      }).catch((err)=>{
        console.log(err);
      });

    }

    // async function _loadPostValue(){
    //   console.log(props.questions.PostId);
    //   if(props.questions.PostId != 0){
       
    //     postList.value.array.forEach(element => {
    //       if(element.id == props.questions.PostId){
    //         props.questions.post = element;
    //       }
    //     });
    //   }

    // }
    
    function test(val){
      console.log("-------------");
      console.log(val);
    }

    async function onScroll ({ to, ref }) {
        const lastIndex = postList.value.length - 1;
        if (loading.value !== true && to === lastIndex) {
          loading.value = true;

          await _getPostList();

          loading.value = false
        }
    }

    return {
      loading,
      postList,

      onScroll,
      test
    }
  }
};
</script>

<style lang="scss" scoped>
.material-profile {
  border: 1px solid #e3e8eb;
  border-radius: 20px;
}
.title-info {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
}
</style>
